<template>
  <div class="header">
    <router-link class="logo" :to="{ name: 'index' }">
      <img :src="`${publicPath}img/logo.png`" class="logo__image"/>
    </router-link>
    <div class="nav-links">
      <div class="apps">
        <a href="#"
           v-scroll-to="{ el: '#'+key, onStart: () => this.$router.push({ name: 'index', hash: '#'+key  })}"
           v-for="(app, key) in applications" v-show="!app.group">
          {{ app.name }}
        </a>
        <div class="dropdown" v-for="group in this.getAllGroups()">
          <span class="dropdown__link">{{ group }}</span>
          <div class="dropdown__content">
            <a href="#"
               v-scroll-to="{ el: '#'+key, onStart: () => this.$router.push({ name: 'index', hash: '#'+key  })}"
               v-for="(app, key) in applications"
               v-show="app.group === group"
               class="dropdown__content-item">{{ app.name }}</a>
          </div>
        </div>
      </div>
      <router-link class="feedback" :to="{ name: 'feedback' }">
        Send message
      </router-link>
    </div>
  </div>
</template>
<script>
import applications from '../../json/applications.json';

export default {
  name: 'Header',
  data: function() {
    return {
      title: process.env.VUE_APP_TITLE,
      publicPath: process.env.BASE_URL,
      applications,
    };
  },
  methods: {
    getAllGroups: function() {
      return Object.values(applications).map(a => a.group).filter((v, i, a) => a.indexOf(v) === i && v !== undefined);
    },
  },
};
</script>
<style lang="less">
@import "style.less";
</style>