<template>
  <div class="container">
    <header-vue></header-vue>
    <router-view/>
    <footer-vue></footer-vue>
  </div>
</template>

<script>
import header from './components/header/index.vue';
import footer from './components/footer/index.vue';

export default {
  name: "App",
  data() {
    return {}
  },
  components: {
    headerVue: header,
    footerVue: footer
  },
}
</script>

<style lang="less">
@mobile: 1024px;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: "San Francisco Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #6F7482;
  letter-spacing: -0.32px;

  .container {
    width: 970px;
    height: 100%;

    @media (max-width: @mobile) {
      width: 100%;
      padding: 0 20px;
      margin-top: 87px;
    }
  }
}

a {
  text-decoration: none;
  font-family: "San Francisco Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #6F7482;
  letter-spacing: -0.32px;
}

@font-face {
  font-family: "San Francisco Pro Text";
  src: url("./fonts/SFProText/SF-Pro-Text-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}
</style>
