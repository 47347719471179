<template>
  <div class="page">
    <div class="page__title">
      {{ this.$route.meta.title }}
    </div>
    <div class="page__content" v-html="text"></div>
  </div>
</template>
<script>
import applications from '../../json/applications.json';

export default {
  name: 'tos',
  data: function() {
    return {
      text: '',
    };
  },
  watch: {
    $route(to, from) {
      this.getPage(this.$route.params.id);
    },
  },
  methods: {
    getPage: function(app_id) {
      if (!!applications[app_id]) {
        const application = applications[app_id];
        const text = require(`@/json/pages/tos.html`).default;
        const mapObj = {
          '%name%': application.name,
          '%account%': process.env.VUE_APP_ACCOUNT,
          '%email%': process.env.VUE_APP_EMAIL,
        };
        this.text = text.replace(/(%name%|%account%|%email%)/gi, matched => mapObj[matched]);
      } else {
        this.text = 'Page not found';
      }
    },
  },
  mounted() {
    this.getPage(this.$route.params.id);
  },
};
</script>
<style lang="less">
@import "style.less";
</style>